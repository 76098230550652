export const REACT_APP_API_URL = "{{API_URL}}";
export const API_URL_CONSUMER = "{{API_URL_CONSUMER}}";
export const ST_LANGUAGE = "lang"
export const MAX_PAX_ADULT = "{{MAX_PAX_ADULT}}";
export const MAX_PAX_CHILDREN = "{{MAX_PAX_CHILDREN}}";
export const SCAN_TIME_AVAIABLE = "{{SCAN_TIME_AVAIABLE}}";
export const GA_ID = "{{GA_ID}}";
export const UTM_SOURCE = "{{UTM_SOURCE}}";
export const TIME_INTERVALS = "{{TIME_INTERVALS}}";
export const GTM_ID = "{{GTM_ID}}";
export const FACEBOOK_PIXEL_ID = "{{FACEBOOK_PIXEL_ID}}";
export const REDIRECT_LOGOUT_URL = "{{REDIRECT_LOGOUT_URL}}";
export const TOKEN_SHARE = "{{TOKEN_SHARE}}";
export const REMEMBER_SHARED_TOKEN = 14;
export const SUB_DOMAIN = "{{SUB_DOMAIN}}";
export const REDIRECT_URL_PROFILE = "{{REDIRECT_URL_PROFILE}}";
export const REDIRECT_LOGIN_URL = "{{REDIRECT_LOGIN_URL}}";
export const REDIRECT_DELIVERY_SITE = "{{REDIRECT_DELIVERY_SITE}}";

export const FIREBASE_CONFIG = {
  apiKey: "{{FIREBASE_API_KEY}}",
  authDomain: "{{FIREBASE_AUTH_DOMAIN}}",
  databaseURL: "{{FIREBASE_DATABASE_URL}}",
  projectId: "{{FIREBASE_PROJECT_ID}}",
  storageBucket: "{{FIREBASE_STORAGE_BUCKET}}",
  messagingSenderId: "{{FIREBASE_MESSAGING_SENDER_ID}}",
  appId: "{{FIREBASE_APP_ID}}",
  measurementId: "{{FIREBASE_MEASURE_ID}}"
};
export const GOOGLE_API_KEY = "{{GOOGLE_API_KEY}}";
export const IS_SYSTEM_NOTIFICATION = "{{IS_SYSTEM_NOTIFICATION}}";
export const MEDIA_LINKS_MENU_PDF = "https://pizza4ps.com/vn/menu";
export const MEDIA_LINKS_BANNER = "{{MEDIA_LINKS_BANNER}}";
export const API_URL_CUSTOMER_V3 = "{{API_URL_CUSTOMER_V3}}" || "https://uat-deli-v2.pizza4ps.io/v3";

export const STORE_IDS = {
   PhuMyHung_HCM: '5fa9d8e1aafd861b1de36b2e',
   HaDong_HN: '64d9c5d7247dad0256f39e21',
}