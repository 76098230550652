import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Fetch from "i18next-fetch-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import * as CONFIG from "./config/index";
import Cookies from "js-cookie";

i18n
  .use(Fetch)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: function (lng, ns) {
        return `/locales/${lng}/${ns}.json`;
      },
    },
    fallbackLng: "vi",
    lng:
      Cookies.get(CONFIG.ST_LANGUAGE) ||
      localStorage.getItem(CONFIG.ST_LANGUAGE) ||
      "vi",
    debug: false,
    ns: ["common"],
    defaultNS: "common",
    keySeparator: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });
export default i18n;
