import { v4 as uuidv4 } from "uuid";
import { platformContants } from "@iso/utils/constants";
import UserTracktringService from "@iso/services/UserTrackingService";

export default function useTrackingActions() {
  return {
    post,
  };

  async function post({ action, items }) {
    if (!sessionStorage.getItem("session_tracking_user")) {
      sessionStorage.setItem("session_tracking_user", uuidv4());
    }
    const sessionString = sessionStorage.getItem("session_tracking_user");
    const currentPage = window?.location?.href || "";

    const latLng = localStorage.getItem('user_location') ? JSON.parse(localStorage.getItem('user_location')) : null;
    const blockage = localStorage.getItem('blockage') ? JSON.parse(localStorage.getItem('blockage')) : null;


    const trackingItemInfo = {
      date: items?.date || null,
      time: items?.time || null,
      pax_number: items?.pax_number || null,
      area: items?.area || null,
      store: items?.store || null,
      response: items?.response || null,
      reservation_id: items?.reservation_id || null,
      sitting_area: items?.sitting_area || null,
      note: items?.note || null,
      eldery_qty: items?.eldery_qty || null,
      children_qty: items?.children_qty || null,
      is_notify_sms: items?.is_notify_sms || false
    };

    const body = {
      action,
      page: currentPage,
      item: trackingItemInfo,
      user: {
        name: items?.name ? items?.name : blockage?.reservation?.first_name ? blockage?.reservation?.first_name : '',
        phone: items?.phone ? items?.phone : blockage?.reservation?.phone ? blockage?.reservation?.phone : '',
        email: items?.email ? items?.email : blockage?.reservation?.email ? blockage?.reservation?.email : '',
      },
      platform: window.innerWidth < 768 ? platformContants.MOBILE : platformContants.DESKTOP,
      session_string: sessionString || "",
    };

    if(latLng){
      body.lat = latLng?.lat;
      body.lng = latLng?.lng;
    }
    
    try {
      return await UserTracktringService.post(body);
    } catch (error) {
      console.error(error);
    }
  }
}
