import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { TOKEN_SHARE } from "@iso/config/index";
import Loader from "@iso/layouts/partial/loader";
import ErrorBoundary from "./ErrorBoundary";
import withTracker from "@iso/utils/withTracker.js";
import {
  clearToken,
  getCookie,
  getToken,
  isMacBrowser,
  removeCookie,
  set_cookie_shared_token,
} from "@iso/utils/helpers";
import { firebase_app } from "@iso/config/base";
import HomeService from "@iso/services/HomeService";
import { get } from "lodash";
import { SUCCESS_STATUS } from "@iso/utils/constants";
import WaitList from "./pages/WaitList/WaitList";
import ChooseRestaurants from "./pages/ChooseRestaurants/ChooseRestaurants";
import UserWaitList from "./pages/UserWaitList/UserWaitList";
import UserWaitListConfirm from "./pages/UserWaitList/UserWaitListConfirm";
import { setAuthorization } from "./utils/axios.configs";
import { v4 as uuidv4 } from "uuid";
import { GetProfileV3 } from "./services/AuthService";
import ModalWebview from "./component/ModalWebview/index";

const Main = lazy(() => import("@iso/pages/main"));
const Booking = lazy(() => import("@iso/pages/Booking/Booking"));
const ConfirmBooking = lazy(() => import("@iso/pages/Booking/Confirm"));
const CancelBooking = lazy(() => import("@iso/pages/Booking/Cancel"));
const Term = lazy(() => import("@iso/pages/Booking/Term"));
const PrivacyPolicy = lazy(() => import("@iso/pages/Booking/Privacy"));
const Authen = lazy(() => import("@iso/pages/Authen"));
//const ChooseRestaurants = lazy(() => import("@iso/pages/ChooseRestaurants"));

// function PrivateRoute({ component: Component, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         getToken() ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/authen",
//             }}
//           />
//         )
//       }
//     />
//   );
// }

function AuthenRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !getToken() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const logout = () => {
  firebase_app.auth().signOut();
  clearToken();
  localStorage.removeItem("user_profile");
  localStorage.removeItem("firebase_user_id");
  if (
    getCookie("isLogoutSSO") &&
    getCookie("isLogoutSSO").toString() === "true"
  ) {
    removeCookie("isLogoutSSO");
  }
};

export default function Routes() {
  const [userToken, setUserToken] = useState(null);
  async function get_user_token() {
    var user = firebase_app.auth().currentUser;
    var token_user = null;
    if (user) {
      try {
        const token = await user.getIdToken();
        const res_data = await HomeService.get_sso_token(token);
        token_user = get(res_data, "data.token", null);
      } catch (error) {
        console.error(error);
        token_user = null;
      }
    } else {
      token_user = null;
    }
    return token_user;
  }

  function check_user_token(token) {
    if (token) {
      set_cookie_shared_token(TOKEN_SHARE, token);
      if (isMacBrowser) {
        setUserToken(token);
      }
      HomeService.get_user_profile(token).then((res) => {
        localStorage.setItem("user_profile", JSON.stringify(res.data));
      });
    } else {
      setUserToken(null);
    }
  }

  async function create_token_custom() {
    //STEP 1 -> get token User(Token_share) -> ném vô ->/v2/api/customers/common/createSessionCookie
    //STEP 2 -> ném vô /v2/api/customers/common/createCustomToken
    // -> sau đó signInWithCustomToken với  custom token đó
    // -> tiếp theo dùng userCredential trả ra rồi dùng getIdToken lấy token mới

    //TODO check - >_session_token
    //TODO check - >_session_token -> invalid
    try {
      if(getToken()){
        const res = await GetProfileV3();

        console.log(res);
        localStorage.setItem(
          "firebase_user_id",
          res?.data?.id
        );
        localStorage.setItem("user_profile", JSON.stringify(res?.data));
      }
    } catch (error) {
      console.error("Check Auth state fail", error);
    }
  }
  useEffect(() => {
    // get __token cookies
    // subscribe firebase user
    let __token = getToken();
    let unsubscribe = null;
    if (__token) {
      create_token_custom();
    } else {
      logout();
    }
    return () => {
      if (unsubscribe !== null) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
         const { coords } = position;
         const { latitude, longitude } = coords;
         localStorage.setItem(
          "user_location",
          JSON.stringify({
            lat: latitude,
            lng: longitude,
          })
        );
      });
   }

    setUserTrackingAction();
  }, []) 

  const setUserTrackingAction = () => {
    const session = sessionStorage.getItem('session_tracking_user');
    if(!session){
      sessionStorage.setItem('session_tracking_user', uuidv4())
    }
  }

  return (
    <ErrorBoundary>
      <Router>
        <Suspense fallback={<Loader />}>
          <div className="l-wrapper">
            <Switch>
              <Route
                exact={true}
                path="/"
                component={withTracker(Main, {
                  /* additional attributes */
                })}
              />
              <Route
                exact={true}
                path="/booking/review/:blockageId"
                component={withTracker(Booking, {
                  /* additional attributes */
                })}
              />
              <Route
                exact={true}
                path="/booking/confirmed/:blockageId"
                component={withTracker(ConfirmBooking, {
                  /* additional attributes */
                })}
              />
              <Route
                exact={true}
                path="/booking/cancel/:blockageId"
                component={withTracker(CancelBooking, {
                  /* additional attributes */
                })}
              />
              <Route
                exact={true}
                path="/terms-service"
                component={withTracker(Term, {
                  /* additional attributes */
                })}
              />
              <Route
                exact={true}
                path="/privacy-policy"
                component={withTracker(PrivacyPolicy, {
                  /* additional attributes */
                })}
              />
              <Route
                exact={true}
                path="/user-wait-list/"
                component={withTracker(UserWaitList, {
                  /* additional attributes */
                })}
              />
              <Route
                exact={true}
                path="/user-wait-list-confirm/"
                component={withTracker(UserWaitListConfirm, {
                  /* additional attributes */
                })}
              />
              <Route
                exact={true}
                path="/dine-in-wait-list/"
                component={withTracker(WaitList, {
                  /* additional attributes */
                })}
              />
              <Route
                exact={true}
                path="/dine-in-wait-list-choose-restaurants/"
                component={withTracker(ChooseRestaurants, {
                  /* additional attributes */
                })}
              />
              <AuthenRoute path="/authen" exact={false}>
                <Authen />
              </AuthenRoute>
            </Switch>
          </div>
        </Suspense>
      </Router>
      <ModalWebview/>
    </ErrorBoundary>
  );
}
