import React, { useState, useEffect } from "react";

function Time(props) {
   const [currentTime, setCurrentTime] = useState(new Date());

   const updateTime = () => {
      setCurrentTime(new Date());
   };

   useEffect(() => {
      const interval = setInterval(() => {
         updateTime();
      }, 1000);
      return () => {
         clearInterval(interval);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <p className="current-time">
         {currentTime.getHours() +
            ":" +
            currentTime.getMinutes().toString().padStart(2, "0")}
      </p>
   );
}

export default Time;
