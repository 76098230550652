import Modal from "react-modal";
import BuuImg from "@iso/assets/images/Buu.png";
import { useState, useEffect } from "react";
import { isWebview } from "@iso/utils/helpers";
function ModalWebview() {
    const [isOpen, setIsOpen] = useState(false);
    const { innerWidth: width } = window;
    let widthModal = width < 768 ? "240px" : "280px";
    let leftModal = width < 768 ? "calc(100% - 250px)" : "500px";
    let heightModal = "fit-content";

    useEffect(() => {
        // if(isWebview()){
        //     setIsOpen(true);
        // }
    }, [])

    const customStyles = {
        content: {
        top: "60px",
        left: leftModal,
        width: widthModal,
        padding: "0px",
        height: heightModal,
        color: "#02499d",
        fontSize: 14,
        },
        overlay: {
            "clip-path":
                "polygon(calc(100% - 105px) 0, calc(100% - 105px)  43px, 100% 43px, 100% 100%, 0 100%, 0 0)",
        },
    };

    return ( 
        <Modal className='modal-webview' isOpen={isOpen} onRequestClose={() => setIsOpen(!isOpen)} styles={customStyles}>
            <div className="">
                <img className='modal-webview__img' src={BuuImg} alt=''/>
                <p className="modal-webview__des">Copy and paste this link into your browser to get the best experience.</p>
            </div>
        </Modal>
     );
}

export default ModalWebview;