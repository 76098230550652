import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import Routes from "./router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@iso/assets/scss/app.scss";
import TagManager from "react-gtm-module";
import { GTM_ID, FACEBOOK_PIXEL_ID } from "./config/index";
import ReactPixel from "react-facebook-pixel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

if (GTM_ID) {
  const tagManagerArgs = {
    gtmId: GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}
if (FACEBOOK_PIXEL_ID) {
  ReactPixel.init(FACEBOOK_PIXEL_ID, {}, { debug: false, autoConfig: false });
  ReactPixel.pageView();
  ReactPixel.fbq("track", "PageView");
}

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <ToastContainer />
    <Routes />
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
