/**
 * Home Service
 */
 import { api } from "@iso/utils/axios.configs";
 import { API_URL_CONSUMER } from "@iso/config/index";
 
 // function getUTCTime(time) {
 //   if (!time) {
 //     return null;
 //   }
 //   if (typeof time.getTime !== "function") time = new Date(time);
 //   let utcDate = new Date(
 //     time.getTime() - time.getTimezoneOffset() * 60 * 1000 * -1
 //   );
 //   return utcDate;
 // }
 const UserTrackingService = {
  post: function (data) {
    const url = `/user-tracking`;
    return api.post(url, data);
  },
 };
 
 export default UserTrackingService;
 