import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import HomeService from "@iso/services/HomeService";
import { toast } from "react-toastify";

function AddForm(props) {
  const { t } = useTranslation();
  const { shopId, setIsLoading, callBackSubmit } = props;

  const [dataForm, changeDataForm] = useState({
    terms: false,
    seat_types: null,
    enable_sms: true,
  });

  let ObjErrors = {
    first_name: {
      is_input_valid: true,
      error_message: "",
    },
    phone: {
      is_input_valid: true,
      error_message: "",
    },
    pax: {
      is_input_valid: true,
      error_message: "",
    },
    memo: {
      is_input_valid: true,
      error_message: "",
    },
  };

  const on_change_input = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    validate_require(value, name);
    changeDataForm({ ...dataForm, [name]: value });
  };

  const on_change_textarea = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    changeDataForm({ ...dataForm, [name]: value });
  };

  const on_change_number_input = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    validate_number_input(value, name);
    changeDataForm({ ...dataForm, [name]: value });
  };

  function on_change_input_phone(value, name) {
    validate_phone_input(value, name);
    changeDataForm({ ...dataForm, [name]: value });
  }

  const on_clear_input = () => {
    changeDataForm({
      ...dataForm,
      first_name: "",
      phone: "",
      pax: "",
      memo: "",
    });
  };

  const [errorsValid, setErrors] = useState(ObjErrors);
  const validate_require = (checking_text, field = "") => {
    if (!checking_text) {
      let errors = errorsValid;
      errors[field] = {
        is_input_valid: false,
        error_message:
          field === "first_name"
            ? t("TC_COMMON_ERR_FIRST_NAME")
            : field === "phone"
            ? t("TC_COMMON_ERR_PHONE")
            : field === "pax"
            ? t("TC_COMMON_ERR_PAX_SIZE")
            : "",
      };
      setErrors({ ...errors });
      return false;
    }
    let errors = errorsValid;
    errors[field] = {
      is_input_valid: true,
      error_message: "",
    };
    setErrors({ ...errors });
    return true;
  };

  function validate_phone_input(checking_text, field = "") {
    let regexp = /^[0-9\+]{11,18}$/;
    const checkingResult = regexp.exec(checking_text);
    let errors = errorsValid;
    if (!checkingResult) {
      errors[field] = {
        is_input_valid: false,
        error_message: t("TC_COMMON_ERR_PHONE_IS_INVALID"),
      };
      setErrors({ ...errors });
      return false;
    }
    errors[field] = {
      is_input_valid: true,
      error_message: "",
    };
    setErrors({ ...errors });
    return true;
  }

  function validate_number_input(checking_text, field = "") {
    if (!checking_text) {
      validate_require(checking_text, field);
    } else {
      let regexp = /^[1-9]*$/;
      const checkingResult = regexp.exec(checking_text);
      let errors = errorsValid;
      if (!checkingResult) {
        errors[field] = {
          is_input_valid: false,
          error_message: t("TC_COMMON_ERR_PAX_SIZE_IS_INVALID"),
        };
        setErrors({ ...errors });
        return false;
      }
      errors[field] = {
        is_input_valid: true,
        error_message: "",
      };
      setErrors({ ...errors });
      return true;
    }
  }

  const handleSubmit = (e) => {
    const { first_name = "", phone = null, pax = 0, memo = "" } = dataForm;
    e.preventDefault();
    if (
      !validate_require(first_name.trim(), "first_name") ||
      !errorsValid.first_name.is_input_valid ||
      !validate_phone_input(phone, "phone") ||
      !validate_number_input(pax, "pax")
    ) {
      //setLoadingForm(false);
      return;
    }
    setIsLoading(true);

    const body = {
      enable_sms: true,
      first_name: first_name ? first_name.trim() : "",
      phone: phone,
      pax: pax,
      locale: "en",
      shop_id: shopId,
      memo: memo,
    };

    HomeService.post_wait_list(body).then((res) => {
      on_clear_input();
      callBackSubmit(phone);
      toast.success(t("TC_CREATE_WAITLIST_SUCCESS"));
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-group">
        <label htmlFor="name" className="u-line-clamp-1 require">
          {t("TC_WAITLIST_LABLE_GUEST")}
        </label>
        <input
          name="first_name"
          id="first_name"
          autoComplete="off"
          placeholder={t("TC_WAITLIST_LABLE_GUEST")}
          onChange={(e) => {
            on_change_input(e);
          }}
          value={dataForm.first_name}
        />
        <span className="err-label">
          {!errorsValid.first_name.is_input_valid &&
            errorsValid.first_name.error_message}
        </span>
      </div>
      <div className="input-group">
        <label htmlFor="phone" className="u-line-clamp-1 require">
          {t("TC_WAITLIST_LABLE_PHONE")}
        </label>
        <PhoneInput
          international
          countryCallingCodeEditable={true}
          defaultCountry="VN"
          value={dataForm.phone || null}
          onChange={(val) => {
            on_change_input_phone(val, "phone");
          }}
          className="PhoneInputCountryInternational"
        />
        <span className="err-label">
          {!errorsValid.phone.is_input_valid && errorsValid.phone.error_message}
        </span>
      </div>
      <div className="input-group">
        <label htmlFor="number-of-peple" className="u-line-clamp-1 require">
          {t("TC_WAITLIST_LABLE_PAX_SIZE")}
        </label>
        <input
          type="number"
          name="pax"
          id="pax"
          autoComplete="off"
          placeholder={t("TC_BOOKING_LABLE_PEOPLE")}
          onChange={(e) => {
            on_change_number_input(e);
          }}
          value={dataForm.pax}
        />
        <span className="err-label">
          {!errorsValid.pax.is_input_valid && errorsValid.pax.error_message}
        </span>
      </div>
      <div className="input-group">
        <label htmlFor="number-of-peple" className="u-line-clamp-1 require">
          {t("TC_BOOKING_LABLE_DESCRIPTION")}
        </label>
        <textarea
          name="memo"
          id="memo"
          placeholder={t("TC_BOOKING_LABLE_DESCRIPTION_PLACEHOLDER")}
          rows={4}
          onChange={(e) => {
            on_change_textarea(e);
          }}
          value={dataForm.memo}
        />
      </div>
      <div className="form-bottom">
        <button
          className="actions actions--warring"
          type="button"
          onClick={() => on_clear_input()}
        >
          {t("TC_COMMON_BUTTON_CLEAR")}
        </button>
        <button className="actions actions--primary">
          {t("TC_COMMON_BUTTON_SUBMIT")}
        </button>
      </div>
    </form>
  );
}

export default AddForm;
