import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getUrlParam, createUrl } from "@iso/utils/url_handler";
import HomeService from "@iso/services/HomeService";
import Select from "react-select";
import { ReactComponent as IconArrow } from "@iso/assets/images/icon_arrow_down.svg";
import icon_lookup from "@iso/assets/images/icon_lookup.svg";
import NavBar from "@iso/component/NavBar/NavBar";
import useTrackingActions from "@iso/utils/userTracking";
import { actionsTracking } from "@iso/utils/constants";

function ChooseRestaurants() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState({ list: [], select: null });
  const [restaurants, setRestaurants] = useState({
    list: [],
    select: { value: 0, label: t("TC_TITLE_HEADING_RESTAURANTS_NAME") },
  });
  const userTracking = useTrackingActions();

  const loadRestaurants = (locationId) => {
    setRestaurants({
      list: [],
      select: { value: 0, label: t("TC_TITLE_HEADING_RESTAURANTS_NAME") },
    });
    HomeService.get_store_by_location(locationId).then((res) => {
      const response_stores = res.data.shops.map((element) => {
        const { id, name_translations } = element;
        const { en } = name_translations;
        return {
          value: id,
          label: en,
        };
      });
      setIsLoading(false);
      setRestaurants({ list: response_stores, select: response_stores[0] });
    });
  };

  const onChangeLocation = (item) => {
    setIsLoading(true);
    loadRestaurants(item.id);
    setLocations({ ...locations, select: item });
    history.push(
      createUrl({
        getLatLng: item.id,
      })
    );
  };

  const generateSelectionLocationList = () => {
    const availableSelectionLocation = locations.list.filter((element) => {
      return element.id !== locations.select.id;
    });
    return availableSelectionLocation.map((item, index) => {
      return (
        <span
          key={index}
          onClick={() => {
            onChangeLocation(item);
          }}
        >
          {item.label.vi}
        </span>
      );
    });
  };

  const loadData = () => {
    HomeService.get_locations().then((res) => {
      if (!getUrlParam()["getLatLng"]) {
        setLocations({ list: res.data, select: res.data[0] });
        loadRestaurants(res.data[0].id);
      } else {
        const location = res.data.find((element) => {
          return element.id === getUrlParam()["getLatLng"];
        });
        setLocations({ list: res.data, select: location });
        loadRestaurants(location.id);
      }
    });
  };

  function handleSubmit(e) {
    e.preventDefault();
    history.push(`/dine-in-wait-list/?restaurants=${restaurants.select.value}`);
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="choose-restaurants-page">
      <div>
        <NavBar notLogin />
        <div className="l-main u-text-center min-h-screen">
          <div className="l-main__content">
            <h2 className="c-heading-3 w-md c-heading-3-change-location">
              <span className="c-location__selection">
                {locations.list && generateSelectionLocationList()}
              </span>
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="p-search-bar u-mt-15">
                <div className="p-search-bar__content">
                  <div className="p-search-bar__group-expanded u-border-transaprent">
                    <label htmlFor="restaurants" className="u-line-clamp-1">
                      {`${t("TC_SEARCH_LABEL_RESTAURANTS")} ${
                        locations.select && locations.select.label.vi
                      }`}
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="restaurants"
                      id="restaurants"
                      isSearchable={false}
                      isClearable={false}
                      isDisabled={isLoading}
                      value={restaurants.select}
                      placeholder={t("TC_EMPTY_LABEL_RESTAURANTS")}
                      onChange={(val) =>
                        setRestaurants({
                          ...restaurants,
                          select: val,
                        })
                      }
                      options={restaurants.list}
                      styles={customStyles}
                      components={{
                        DropdownIndicator: () => <IconArrow />,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <button
                    disabled={restaurants.select.value === 0}
                    className="c-btn-search"
                    type="submit"
                    //PENDING
                  >
                    <img src={icon_lookup} alt="TC" />
                    {t("TC_COMMON_BUTTON_SEARCH")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="wrapper-loading">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
}
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#ffff" : "#02499D",
    backgroundColor: state.isSelected ? "#02499D" : "#ffff",
    padding: 10,
    fontSize: 14,
    "&:hover": {
      backgroundColor: state.isSelected ? "#02499D" : "#f0f0f0",
    },
  }),
  input: (state) => ({
    ...state,
    padding: 0,
  }),
  control: (state) => ({
    // none of react-select's styles are passed to <Control />
    ...state,
    width: "100%",
    display: "flex",
    backgroundColor: "transparent",
    border: 0,
    boxShadow: "none",
    fontSize: 14,
    borderRadius: 0,
    margin: 0,
    minHeight: 0,
    padding: 0,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#a9a9a9",
      margin: 0,
      padding: 0,
      minHeight: 21,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    };
  },
  valueContainer: (state) => {
    return {
      ...state,
      margin: 0,
      minHeight: 25,
      padding: 0,
    };
  },
  clearIndicator: (state) => {
    return {
      ...state,
      margin: 0,
      padding: 0,
    };
  },
  indicatorSeparator: (state) => {
    return {
      ...state,
      margin: 0,
      padding: 0,
    };
  },
  indicatorsContainer: (state) => {
    return {
      ...state,
      margin: 0,
      padding: 0,
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition, cursor: "text" };
  },
};

export default ChooseRestaurants;
