import React from "react";
import { useTranslation } from "react-i18next";
import "@iso/assets/images/bg.jpg";
import "react-phone-number-input/style.css";
import Modal from "react-modal";
import { ReactComponent as CloseIcon } from "@iso/assets/images/icon_clear_input.svg";

export default function ModalSuggestionsLogin(props) {
  const { t } = useTranslation();

  const { innerWidth: width } = window;
  let widthModal = width < 768 ? "240px" : "280px";
  let leftModal = width < 768 ? "calc(100% - 250px)" : "calc(100% - 290px)";
  let heightModal = "fit-content";
  const customStyles = {
    content: {
      top: "60px",
      left: leftModal,
      width: widthModal,
      padding: "0px",
      height: heightModal,
      color: "#02499d",
      fontSize: 14,
    },
    overlay: {
      "clip-path":
        "polygon(calc(100% - 105px) 0, calc(100% - 105px)  43px, 100% 43px, 100% 100%, 0 100%, 0 0)",
    },
  };

  return (
    <div className="head-info">
      <Modal
        isOpen={props.isShow}
        style={customStyles}
        onRequestClose={props.closeModal}
        className="alert-modal-login"
      >
        <div className="btn-close-modal">
          <CloseIcon onClick={props.closeModal} />
        </div>
        <div className="alert-modal">{t("TC_BOOKING_LOGIN_SUGGESTION")}</div>
      </Modal>
    </div>
  );
}
