import {
  TOKEN_SHARE,
  SUB_DOMAIN,
  REDIRECT_LOGOUT_URL,
  REMEMBER_SHARED_TOKEN,
} from "@iso/config/index";
import { firebase_app } from "@iso/config/base";
import Cookies from "js-cookie";
import { get } from "lodash";
import HomeService from "@iso/services/HomeService";
// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export function isEmpty(input) {
  if (input === null || input === undefined) {
    return true;
  }
  if (typeof input === "string" && input.trim() === "") {
    return true;
  }
  if (Array.isArray(input) && input.length === 0) {
    return true;
  }
  if (typeof input === "object" && Object.keys(input).length === 0) {
    return true;
  }
  return false;
}

export function get_address(obj) {
  if (isEmpty(obj)) {
    return "";
  } else {
    return [
      get(obj, "street", ""),
      get(obj, "street2", ""),
      get(obj, "city", ""),
    ].join(", ");
  }
}

export function redirect_booking(
  searchOptions,
  slug,
  time_valid,
  lang,
  ads_params,
  user_token,
  id
) {
  HomeService.post_blockages(id, time_valid, searchOptions.adult.value)
    .then((res) => {
      if (res.data) {
        // let d = new Date(time_valid);
        // var date = d.getDate();
        // var month = d.getMonth() + 1;
        // var year = d.getFullYear();
        // var minutes =
        //   d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
        // var hour = d.getHours();
        // let dateStr = year + "/" + month + "/" + date;
        // let timeStr = hour + ":" + minutes;
        // let url = `https://www.tablecheck.com/${lang}/shops/${slug}/reserve/?num_people_adult=${get(
        //   searchOptions.adult,
        //   "value",
        //   ""
        // )}&num_people_baby=${get(
        //   searchOptions.child,
        //   "value",
        //   ""
        // )}&start_date=${dateStr}&start_time=${timeStr}`;
        // let url = `/booking/review/?num_people_adult=${get(
        //   searchOptions.adult,
        //   "value",
        //   ""
        // )}&num_people_baby=${get(
        //   searchOptions.child,
        //   "value",
        //   ""
        // )}&start_date=${dateStr}&start_time=${timeStr}&bockageId=${
        //   res.data.blockage.id
        // }`;
        // if (GA_ID) {
        //   url += `&utm_source=${UTM_SOURCE}`;
        // }
        // if (user_token) {
        //   url += `&user_token=${user_token}`;
        // }
        // if (ads_params) {
        //   url += `${ads_params}`;
        // }
        let url = `/booking/review/${res.data.blockage.id}`;
        window.location = url;
      }
    })
    .catch((err) => console.log(err));
}

export function getToken(params) {
  console.log(Cookies.get(params ? params : TOKEN_SHARE));
  return Cookies.get(params ? params : TOKEN_SHARE);
}


export function getRefreshToken() {
  return Cookies.get("refreshTokenShare");
}

export function setToken(token) {
  Cookies.set(TOKEN_SHARE, token);
}

export function getCookie(name) {
  return Cookies.get(name);
}

export function removeCookie(name) {
  Cookies.remove(name, { path: "/", domain: SUB_DOMAIN });
}

export function set_cookie_shared_token(name, value) {
  const date = new Date();
  let options = {
    path: "/",
    domain: SUB_DOMAIN,
    expires: date.addDays(REMEMBER_SHARED_TOKEN),
  };
  Cookies.set(name, value, options);
}

export function clearToken() {
  Cookies.remove(TOKEN_SHARE, { path: "/", domain: SUB_DOMAIN });
  localStorage.removeItem("firebase_user_id");
}

export function LogoutSystem() {
  firebase_app
    .auth()
    .signOut()
    .then(() => {
      redirect_url();
    })
    .catch((error) => {
      redirect_url();
    });
}
export function redirect_url() {
  clearToken();
  setTimeout(function () {
    window.location.href = REDIRECT_LOGOUT_URL;
  }, 200);
}

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

export const isMacBrowser = /Mac|iPod|iPhone|iPad/.test(navigator.platform);

export const convertTZ = (date, tzString) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

export function isWebview() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return (
      // Check for standalone mode on iOS devices
      window.navigator.standalone ||
      // Check if the user agent contains 'wv' (WebView) on Android devices
      (userAgent.includes('android') && (userAgent.includes('wv') || userAgent.includes('x5webkit'))) ||
      // Check for browsers that include 'CriOS' (Chrome for iOS) or 'FxiOS' (Firefox for iOS)
      (userAgent.includes('crios') || userAgent.includes('fxios')) ||
      // Check for Zalo in the user agent
      userAgent.includes('zalo')
  );
}