import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TableWait from "../WaitList/TableWait";
import HomeService from "@iso/services/HomeService";
import NavBar from "@iso/component/NavBar/NavBar";

function UserWaitListConfirm(props) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(true);
  const [waitList, setWaitList] = useState([]);

  const loadData = () => {
    const phone = queryParams.get("phone").toString().trim();
    const storeId = queryParams.get("restaurants").toString();
    HomeService.get_wait_list(phone, storeId).then((res) => {
      setWaitList(res.data.reservations);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <div>
      <NavBar notLogin />
      <div
        className="l-main u-text-center user-wait-list-page min-h-screen u-align-items-center"
        style={{ display: "flex" }}
      >
        <div className="l-main__content u-mb-20">
          <div className="l-main__content-right">
            <div className="u-flex u-align-items-center">
              <h1>{t("TC_TITLE_HEADING_WAIT_LIST_TIMMING")}</h1>
            </div>
            <TableWait list={waitList} />
          </div>
        </div>
        {isLoading && (
          <div className="wrapper-loading">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserWaitListConfirm;
