export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm";
export const SUCCESS_STATUS = {
    SUCCESS_CODE : 200,
    SUCCESS_CREATED : 201,
};

export const platformContants = {
    MOBILE: "MOBILE",
    DESKTOP: "DESKTOP",
}

export const actionsTracking = {
    VIEW_HOME_PAGE: "VIEW_HOME_PAGE",
    RE_CHOOSE_SEARCH_PAXNUMBER: "RE_CHOOSE_SEARCH_PAXNUMBER",
    RE_CHOOSE_SEARCH_STORE: "RE_CHOOSE_SEARCH_STORE",
    RE_CHOOSE_SEARCH_DATE: "RE_CHOOSE_SEARCH_DATE",
    RE_CHOOSE_SEARCH_TIME: "RE_CHOOSE_SEARCH_TIME",
    RE_CHOOSE_AREA: "RE_CHOOSE_AREA",
    SEARCH_RESERVATION: "SEARCH_RESERVATION",
    BOOK_RESERVATION_RECOMMEND: "BOOK_RESERVATION_RECOMMEND",
    BOOK_RESERVATION_EQUAL_SEARCH_FROM_AVAILABLE: "BOOK_RESERVATION_EQUAL_SEARCH_FROM_AVAILABLE",
    BOOK_RESERVATION_DIFFERENT_SEARCH_FROM_AVAILABLE: "BOOK_RESERVATION_DIFFERENT_SEARCH_FROM_AVAILABLE",
    BOOK_RESERVATION_EQUAL_SEARCH_FROM_SUGGESTION: "BOOK_RESERVATION_EQUAL_SEARCH_FROM_SUGGESTION",
    BOOK_RESERVATION_DIFFERENT_SEARCH_FROM_SUGGESTION: "BOOK_RESERVATION_DIFFERENT_SEARCH_FROM_SUGGESTION",
    CANCEL_RESERVATION_SUCCESSFULL: "CANCEL_RESERVATION_SUCCESSFULL",
    BOOK_RESERVATION_WAIT_LIST_FROM_SUGGESTION: "BOOK_RESERVATION_WAIT_LIST_FROM_SUGGESTION",
    RE_CHOOSE_ANOTHER_RESERVATION: "RE_CHOOSE_ANOTHER_RESERVATION",
    ON_CLICK_BTN_HOTLINE: "ON_CLICK_BTN_HOTLINE",
    BOOK_WAITLIST_SUCCESSFULL: "BOOK_WAITLIST_SUCCESSFULL",
    RE_CHOOSE_SITTING_AREA: "RE_CHOOSE_SITTING_AREA",
    BOOK_RESERVATION_SUCCESSFULL: "BOOK_RESERVATION_SUCCESSFULL",
    CANCEL_RESERVATION_FROM_CONFIRM_RESERVATION: "CANCEL_RESERVATION_FROM_CONFIRM_RESERVATION",
    VIEW_GRAND_MENU_FROM_CONFIRM_RESERVATION: "VIEW_GRAND_MENU_FROM_CONFIRM_RESERVATION",
    BOOK_RESERVATION_FAIL: "BOOK_RESERVATION_FAIL",
    ON_CLICK_BTN_GO_TO_DELIVERY_SITE: "ON_CLICK_BTN_GO_TO_DELIVERY_SITE"
  };
  