/**
 * Home Service
 */
import { api } from "@iso/utils/axios.configs";
import { API_URL_CONSUMER } from "@iso/config/index";

// function getUTCTime(time) {
//   if (!time) {
//     return null;
//   }
//   if (typeof time.getTime !== "function") time = new Date(time);
//   let utcDate = new Date(
//     time.getTime() - time.getTimezoneOffset() * 60 * 1000 * -1
//   );
//   return utcDate;
// }
const HomeService = {
  get_shop_availability: function (res) {
    let body = {
      datetime: res.datetime,
      pax: res.pax,
      address_city: res.address_city,
      per_page: res.per_page,
      smoking: res.smoking,
    };

    const url = `/proxy/shops`;
    return api.get(url, { params: body });
  },

  get_timetable_by_shop_id: function (shop_id, res) {
    let body = {
      pax: res.pax,
      datetime: res.datetime,
    };
    const url = `/proxy/timetable/${shop_id}`;
    return api.get(url, { params: body });
  },

  get_locations: function () {
    const url = `/common/cities`;
    return api.get(url);
  },
  create_wailist: function (shop_id, data) {
    const url = `/proxy/shops/${shop_id}/waitlist`;
    return api.post(url, data);
  },

  get_sso_token: function (token) {
    const url = `/sso/token`;
    return api.post(
      url,
      {},
      {
        headers: {
          Authorization: "Bearer " + token, //the token is a variable which holds the token
        },
      }
    );
  },
  create_custom_token: function (data) {
    const url = `/customers/common/createCustomToken`;
    return api.post(url, data, { baseURL: API_URL_CONSUMER });
  },
  create_session_cookie: function () {
    const url = `/customers/common/createSessionCookie`;
    return api.post(
      url,
      {},
      {
        baseURL: API_URL_CONSUMER,
      }
    );
  },
  get_user_profile: function (userToken) {
    const url = `/customers/profile`;
    // const mockToken =
    //   'eyJhbGciOiJSUzI1NiIsImtpZCI6ImRjNGQwMGJjM2NiZWE4YjU0NTMzMWQxZjFjOTZmZDRlNjdjNTFlODkiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbWFwLTRwcy1wcm9kIiwiYXVkIjoibWFwLTRwcy1wcm9kIiwiYXV0aF90aW1lIjoxNjIzMDQ2ODk2LCJ1c2VyX2lkIjoiOTBkNmx6cUFubFVZV05BV0RFcXhXclNrdTl2MSIsInN1YiI6IjkwZDZsenFBbmxVWVdOQVdERXF4V3JTa3U5djEiLCJpYXQiOjE2MjM3NTIwNDYsImV4cCI6MTYyMzc1NTY0NiwiZW1haWwiOiJkZW1vQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJkZW1vQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6ImN1c3RvbSJ9fQ.F5tHoh8SMSHI8i-nMF2CVfjNdMtTDZvlknxFDQX3bqTkPd3Yz62evjVS_we_YUvLsUdBAWNHhRY2-CgPYbCdUTQbBxNHzAa-tXgM2w8nSizHzC9R65qdWt7nngGOuaQcSi5i_pNXq6d29Ns_MJnCAk5GYFbU0C2MjieFxnk0Sc7pv2Qqx-doORZWDs6EwKM860bl7bSqQNk3_ivP3w7yJ62_oIJ_9fKudlGRmlF9MM-Vuhol2SroCocvXRN7dtHpi0rNj8pgyQ_2RtkKs-0YwnS4JCGbvk_-FhPCOOkA4Hovn8hFLy-y6zA9dCT___6R4KWsyUMxl8l8w4UFv8nibQ';
    return api.get(url, {
      baseURL: API_URL_CONSUMER,
      // headers: {
      //   Authorization: 'Bearer ' + userToken,
      // },
    });
  },
  get_store_by_location: function (location) {
    const url = `/common/store?address_city=${location}`;
    return api.get(url);
  },

  get_store_by_id: function (id) {
    const url = `/common/store?store_id=${id}`;
    return api.get(url);
  },

  get_open_spots: function (phoneNumber, location) {
    const url = `/proxy/shop-recommended`;
    return api.get(url, {
      params: {
        phone: phoneNumber,
        location: location,
      },
    });
  },

  post_blockages: function (id, time, pax) {
    const data = {
      shop_id: id,
      start_at: time,
      pax: pax,
    };
    const url = `/blockages`;
    return api.post(url, data);
  },

  post_reservation: function (data) {
    const url = `/reservations`;
    return api.post(url, data);
  },

  post_wait_list: function (data) {
    const url = `/dine-in/wait-list`;
    return api.post(url, data);
  },

  get_wait_list: function (phone, shop_id) {
    const url = `/dine-in/wait-list?phone=${phone}&shop_id=${shop_id}`;
    return api.get(url);
  },

  get_blockages: function (id) {
    const url = `/blockages/${id}`;
    return api.get(url);
  },

  get_reservation: function (id) {
    const url = `/reservations/${id}`;
    return api.get(url);
  },

  get_cancel_reason: function () {
    const url = `/reservations/cancel-reasons`;
    return api.get(url);
  },

  put_cancel_reason: function (id, data) {
    const url = `/reservations/${id}/cancel`;
    return api.put(url, data);
  },

  get_data_waitlist_by_id: function (id) {
    const url = `dine-in/table-available?shop_id=${id}`;
    return api.get(url);
  },

  put_time_dine_in_wait_list: function (data) {
    const url = `dine-in/table-available`;
    return api.put(url, data);
  },
  get_current_surcharge: function (country) {
    const url = `/common/current-surcharge`;
    return api.get(url, { params: { country: country || "VN" } });
  },

  get_surcharge: function (country) {
    const url = `/common/surcharge`;
    return api.get(url, { params: { country: country || "VN" } });
  },
};

export default HomeService;
