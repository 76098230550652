import React, { useEffect } from "react";
import icon_lookup from "../../assets/images/icon_lookup-wait-list.svg";
import HomeService from "@iso/services/HomeService";
import { useTranslation } from "react-i18next";

function SearchForm(props) {
   const { t } = useTranslation();
   const {
      shopId,
      callBackSearch,
      setIsLoading,
      digits,
      setDigits,
      error,
      setError,
   } = props;

   function validate_digits_input(checking_text) {
      if (!checking_text) {
         setError({
            is_input_valid: false,
            error_message: "Digits là bắt buộc",
         });
         return false;
      }

      let regexp = /^[0-9]{4}$/;
      const checkingResult = regexp.exec(checking_text);
      if (!checkingResult) {
         setError({
            is_input_valid: false,
            error_message: "Digits không hợp lệ",
         });
         return false;
      }

      setError({ is_input_valid: true, error_message: "" });
      return true;
   }

   function on_change_input_digits(value) {
      validate_digits_input(value);
      setDigits(value);
   }

   const onSearch = (e) => {
      e.preventDefault();
      if (!error.is_input_valid) {
         return;
      }
      setIsLoading(true);
      callBackSearch();
   };

   useEffect(() => {
      validate_digits_input(digits);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <form className="search-form" onSubmit={onSearch}>
         <div className="search">
            <div className="search-box">
               <img src={icon_lookup} alt="TC" />
               <input
                  type="number"
                  name="digts"
                  placeholder={t("TC_WAITLIST_LABLE_DIGITS_PHONE")}
                  autoComplete="off"
                  value={digits}
                  onChange={(e) => {
                     on_change_input_digits(e.target.value);
                  }}
               />
            </div>
            <button
               disabled={!error.is_input_valid}
               className="actions actions--primary"
            >
               {t("TC_COMMON_BUTTON_SEARCH")}
            </button>
         </div>
      </form>
   );
}

export default SearchForm;
