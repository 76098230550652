import icPerson from "@iso/assets/images/ic_person2x.png";
import { firebase_app } from "@iso/config/base";
import {
  REDIRECT_LOGOUT_URL,
  REDIRECT_URL_PROFILE,
  ST_LANGUAGE,
} from "@iso/config/index";
import {
  getToken,
  LogoutSystem,
  set_cookie_shared_token,
} from "@iso/utils/helpers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactComponent as IcArrow } from "@iso/assets/images/icon_arrow_right_solid.svg";
import logo from "@iso/assets/images/logo.png";
import ModalSuggestionsLogin from "./ModalSuggestionsLogin";

export default function NavBar(props) {
  const { notLogin } = props;
  const { i18n, t } = useTranslation();
  const [barLogOut, toggleBarLogOut] = useState(false);
  const [is_logging, set_is_logging] = useState(false);
  const [isShowModalSuggestLogin, setIsShowModalSuggestLogin] = useState(false);
  const history = useHistory();

  function on_toggle_language(lang) {
    lang = lang === "vi" ? "en" : "vi";
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    set_cookie_shared_token(ST_LANGUAGE, lang);
  }

  function actLogout() {
    LogoutSystem();
  }

  React.useEffect(() => {
    if (
      !localStorage.getItem("previouslyVisited") &&
      !localStorage.getItem("blockage") &&
      !localStorage.getItem("user_profile")
    ) {
      localStorage.setItem("previouslyVisited", "true");
      setIsShowModalSuggestLogin(true);
    }
    // get __token cookies
    // subscribe firebase user
    let unsubscribe = null;
    const __token = getToken();
    if (__token) {
        // Kiểm tra user firebase exists
        if (localStorage.getItem('user_profile')) {
          set_is_logging(true);
        } else {
          set_is_logging(false);
        }
    } else {
      set_is_logging(false);
    }
    return () => {
      if (unsubscribe !== null) {
        unsubscribe();
      }
    };
  }, []);

  function loginAct() {
    history.push({
      pathname: "/authen",
      state: window.location.href,
    });
  }

  function closeModal() {
    setIsShowModalSuggestLogin(false);
  }

  return (
    <div className="nav_bar">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <div className="usermng">
        <button
          className={
            i18n.language === "vi"
              ? " c-btn--lang c-btn--lang-en"
              : " c-btn--lang c-btn--lang-vi"
          }
          onClick={() => {
            on_toggle_language(i18n.language);
          }}
        />
        {!notLogin && (
          <div
            className={`usermng__user ${is_logging && "active"}`}
            onClick={() => {
              is_logging ? toggleBarLogOut(!barLogOut) : loginAct();
            }}
          >
            {/* <img src={is_logging ? icPerson : icPersonBlue} alt="logo 4ps" /> */}
            {is_logging ? (
              <img src={icPerson} alt="logo 4ps" />
            ) : (
              <div className="usermng__user--notlogin">
                <h2>Login</h2>
                <IcArrow />
              </div>
            )}
          </div>
        )}

        <ModalSuggestionsLogin
          isShow={isShowModalSuggestLogin}
          closeModal={closeModal}
        />
        {barLogOut && (
          <div className="usermng__bar">
            {is_logging && (
              <>
                <a href={REDIRECT_URL_PROFILE} className="usermng__account">
                  {t("TC_MY_ACCOUNT")}
                </a>
                <span onClick={actLogout} className="usermng__logout">
                  {t("TC_LOGOUT")}
                </span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
