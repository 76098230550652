import React from "react";
import { useTranslation } from "react-i18next";

function TableWait(props) {
   const { t } = useTranslation();
   const { list = [] } = props;
   const parseTime = (time) => {
      const date = new Date(time);
      return (
         date.getHours() + ":" + date.getMinutes().toString().padStart(2, "0")
      );
   };
   return (
      <table>
         <thead>
            <tr>
               <th>{t("TC_WAITLIST_LABLE_GUEST")}</th>
               <th>{t("TC_WAITLIST_LABLE_PHONE")}</th>
               <th>{t("TC_WAITLIST_LABLE_PAX_SIZE")}</th>
               <th>{t("TC_SEARCH_LABEL_TIME")}</th>
            </tr>
         </thead>
         <tbody>
            {list.map((item) => (
               <tr key={item.id}>
                  <td>{item.first_name}</td>
                  <td>{item.phone}</td>
                  <td>{item.pax}</td>
                  <td>{item.start_at ? parseTime(item.start_at) : ""}</td>
               </tr>
            ))}
         </tbody>
      </table>
   );
}

export default TableWait;
