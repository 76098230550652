import axios from "axios";
import { REACT_APP_API_URL } from "@iso/config/index.js";
import { clearToken, getToken } from "@iso/utils/helpers";
// eslint-disable-next-line import/no-cycle
import { toast } from "react-toastify";
import { get, isString } from "lodash";
import i18next from "../i18n";

export const api = axios.create({
   baseURL: REACT_APP_API_URL,
   timeout: 30000,
   headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Accept: "application/json",
   },
});

api.interceptors.response.use(
   (response) => response,
   (error) => {
      let message = get(
         error,
         "response.data.message",
         "Sorry, something went wrong"
      );
      let status = get(error, "response.status", null);
      if (status === 401) {
         // toast.error("Token Expired !");
         setTimeout(() => {
            clearToken();
            //   localStorage.setItem("direct_url", window.location.href);
            //   window.location.href = "/authen";
         }, 500);
      } else if (status === 422) {
         if (
            get(error, "response.data.message") ===
            "TC_BOOKING_ERR_MESSAGE_SEAT_TYPES_NOT_AVAILABLE"
         ) {
            toast.error(
               i18next.t("TC_BOOKING_ERR_MESSAGE_SEAT_TYPES_NOT_AVAILABLE")
            );
         } else if (
            get(error, "response.data.message") ===
            "TC_BOOKING_ERR_MESSAGE_CANCELLED"
         ) {
            toast.error(i18next.t("TC_BOOKING_ERR_MESSAGE_CANCELLED"));
         } else if (
            get(error, "response.data.errors.0.code", "") ===
            "reservation_not_created"
         ) {
            toast.error(
               i18next.t("TC_MESSAGE_RESERVATION_COULD_NOT_BE_CREATED")
            );
         } else if (
            get(error, "response.data.errors.0.code", "") ===
            "invalid_date_past"
         ) {
            toast.error(i18next.t("TC_MESSAGE_PAST_DAY_NOT_ALLOWED"));
         } else if (
            get(error, "response.data.errors.0.code", "") === "time_in_past"
         ) {
            toast.error(i18next.t("TC_MESSAGE_RESERVATION_TIME_IN_PAST"));
         } else {
            toast.error(
               get(
                  error,
                  "response.data.errors.0.message",
                  "Sorry, something went wrong"
               )
            );
         }

         //  else {
         //   toast.error(i18next.t("TC_COMMON_ERR_NOT_FOUND"));
         // }
      } else if (status >= 500 || message === "Network Error") {
         toast.error("No internet connection, or backend system has failed!");
      } else if (status === 410) {
      } else {
         if (isString(message)) {
            toast.error(message);
         } else {
            toast.error("Sorry, something went wrong");
         }
      }
      return Promise.reject(error);
   }
);

api.defaults.headers.common.Authorization = getToken()
   ? `Bearer ${getToken()}`
   : null;

export function setAuthorization(token) {
   api.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null;
}

export function removeAuthorization() {
   // for Logout
   setAuthorization(null);
}
