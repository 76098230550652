import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import AddForm from "../WaitList/AddForm";
import HomeService from "@iso/services/HomeService";
import NavBar from "@iso/component/NavBar/NavBar";
import SearchForm from "../WaitList/SearchForm";

function UserWaitList(props) {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [storeInfo, setStoreInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [digits, setDigits] = useState("");
  const [errorDigits, setErrorDigits] = useState({
    is_input_valid: false,
    error_message: "",
  });

  const getInfoShop = () => {
    HomeService.get_store_by_id(queryParams.get("restaurants")).then((res) => {
      setStoreInfo(res.data.shops[0]);
      setIsLoading(false);
    });
  };

  const callBackSubmit = (phone) => {
    setIsLoading(false);
    history.push(
      `/user-wait-list-confirm/?restaurants=${
        storeInfo.id
      }&phone=${phone.replace("+", "")}`
    );
  };

  const callBackSearch = () => {
    history.push(
      `/user-wait-list-confirm/?restaurants=${storeInfo.id}&phone=${digits}`
    );
  };

  useEffect(() => {
    getInfoShop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <NavBar notLogin />
      <div
        className="l-main u-justify-center u-text-center u-flex-col user-wait-list-page min-h-screen u-align-items-center"
        style={{ display: "flex" }}
      >
        <div className="l-main__content u-mb-20">
          <div className="l-main__content-right mobile">
            <SearchForm
              shopId={storeInfo && storeInfo.id}
              callBackSearch={callBackSearch}
              setIsLoading={setIsLoading}
              digits={digits}
              setDigits={setDigits}
              error={errorDigits}
              setError={setErrorDigits}
            />
          </div>
        </div>
        <div className="l-main__content u-mb-20">
          <div className="l-main__content-left">
            <h1>
              {storeInfo
                ? storeInfo.name_translations.en
                : "Resturants name..."}
            </h1>
            <AddForm
              shopId={storeInfo && storeInfo.id}
              setIsLoading={setIsLoading}
              callBackSubmit={callBackSubmit}
            />
          </div>
        </div>
        {isLoading && (
          <div className="wrapper-loading">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserWaitList;
