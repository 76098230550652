import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import "@iso/assets/images/bg.jpg";
import { useLocation, useHistory } from "react-router-dom";
import HomeService from "@iso/services/HomeService";
import Time from "./Time";
import Modal from "react-modal";
import { set_cookie_shared_token } from "@iso/utils/helpers";
import { ST_LANGUAGE } from "@iso/config/index";
import phoneScanqr from "@iso/assets/images/phone_scanqr.png";
import { ReactComponent as ClearIcon } from "@iso/assets/images/icon_clear_input.svg";

function WaitList() {
   const { innerWidth: width } = window;
   let widthModal = width < 768 ? "90%" : "450px";
   const customStyles = {
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         width: widthModal,
         padding: "0px",
         marginRight: "-50%",
         transform: "translate(-50%, -50%)",
      },
   };

   const { i18n, t } = useTranslation();
   const history = useHistory();
   const { search } = useLocation();
   const queryParams = new URLSearchParams(search);
   const [storeInfo, setStoreInfo] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [dataWaitList, setDataWaitList] = useState();
   const [counterClick, setCounterClick] = useState(0);
   const [isShowModal, setIsShowModal] = useState(false);
   const isShowModalRef = useRef(false)
   const [timeWaitList, setTimeWaitList] = useState(
      sessionStorage.getItem("available_minutes") &&
         JSON.parse(sessionStorage.getItem("available_minutes"))
            ?.available_minutes
         ? JSON.parse(sessionStorage.getItem("available_minutes"))
              ?.available_minutes
         : 0
   );
   const [contentHeight, setContentHeight] = useState(0);
   const headRef = useRef(null);

   const handelClick = (value) => {
      if (value === 2) {
         setIsShowModal(true);
         isShowModalRef.current = true
         setCounterClick(0);
      } else {
         setCounterClick(value + 1);
      }
   };

   const hanelChangetimeWaitList = () => {
      setIsLoading(true);
      HomeService.put_time_dine_in_wait_list({
         shop_id: queryParams.get("restaurants"),
         available_minutes: timeWaitList,
      })
         .then((res) => {
            sessionStorage.setItem(
               "available_minutes",
               JSON.stringify(res?.data)
            );
            getDataWaitList();
            setIsShowModal(false);
            isShowModalRef.current = false
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const getDataWaitList = () => {
      if (!isShowModalRef.current) {
         HomeService.get_data_waitlist_by_id(queryParams.get("restaurants"))
         .then((res) => {
            if (res) {
               setDataWaitList(res?.data);
               sessionStorage.setItem(
                  "available_minutes",
                  JSON.stringify(res?.data)
               );
               setTimeWaitList(res?.data?.available_minutes);
            }
            setIsLoading(false);
         })
         .catch((error) => {
            console.log(error);
            setIsLoading(false);
         });
      }
   };

   const getInfoShop = () => {
      if (!queryParams.get("restaurants")) {
         history.push("/dine-in-wait-list-choose-restaurants");
         return;
      }

      HomeService.get_store_by_id(queryParams.get("restaurants")).then(
         (res) => {
            setStoreInfo(res.data.shops[0]);
            setIsLoading(false);
         }
      );
   };

   const handelChangeTime = (action, value) => {
      if (action === "increase") {
         setTimeWaitList(value + 5);
      } else {
         if (value - 5 < 0) {
            setTimeWaitList(0);
         } else if (value !== 0) setTimeWaitList(value - 5);
      }
   };

   const windowHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty(
         "--window-height",
         `${document.documentElement.clientHeight - 1}px`
      );
   };

   useEffect(() => {
      if (headRef.current) {
         setContentHeight(
            document.documentElement.clientHeight - headRef.current.clientHeight
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [headRef.current]);

   useEffect(() => {
      if (1 < counterClick < 3) {
         const timerCouter = setTimeout(() => {
            setCounterClick(0);
         }, 300);
         return () => clearTimeout(timerCouter);
      }
   }, [counterClick]);

   useEffect(() => {
      window.addEventListener("resize", windowHeight);
      windowHeight();

      getInfoShop();
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
      set_cookie_shared_token(ST_LANGUAGE, "en");
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      window.scrollTo(0, 0);

      getDataWaitList();
      const intervalId = setInterval(() => {
         getDataWaitList();
      }, 5000);

      return () => {
         clearInterval(intervalId)
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         <div className="u-text-center wait-list-page">
            {dataWaitList && (
               <>
                  <div className="head" ref={headRef}>
                     <div>
                        {storeInfo
                           ? storeInfo.name_translations.en
                           : "Restaurants name..."}
                        <Time />
                     </div>
                     <h3>Waiting List</h3>
                  </div>
                  <div
                     style={{
                        height:
                           contentHeight > 0 ? `${contentHeight}px` : "100%",
                     }}
                     className={`content ${
                        !dataWaitList?.available_minutes && "table-available"
                     }`}
                  >
                     {dataWaitList?.available_minutes ? (
                        <>
                           <div className="content-left">
                              <h1>
                                 Estimated waiting time
                                 <br />
                                 <span className="italic">
                                    Thời gian chờ dự kiến
                                 </span>
                              </h1>
                              <p
                                 onClick={() => handelClick(counterClick)}
                                 style={{ cursor: "default" }}
                              >
                                 {dataWaitList?.available_minutes || 0} mins
                              </p>
                              <h2>
                                 {dataWaitList?.groups || 0} Waiting groups
                              </h2>
                           </div>
                           <div className="content-right">
                              <h2>
                                 <img src={phoneScanqr} alt="phone Scan qr" />
                                 Scan
                              </h2>
                              <div className="qr-code u-pt-20">
                                 {storeInfo && (
                                    <QRCode
                                       size={
                                          window.screen.width <= 1280
                                             ? 220
                                             : 300
                                       }
                                       fgColor="#02499d"
                                       value={`${window.location.hostname.toString()}/user-wait-list?restaurants=${
                                          storeInfo.id
                                       }`}
                                    />
                                 )}
                              </div>
                              <h3>to register</h3>
                              <p>into the waiting list</p>
                           </div>
                        </>
                     ) : (
                        <div className="content-center">
                           <div>
                              <h1 onClick={() => handelClick(counterClick)}>
                                 TABLE AVAILABLE NOW
                              </h1>
                              <br />
                              <h1
                                 className="italic"
                                 onClick={() => handelClick(counterClick)}
                              >
                                 CÒN BÀN TRỐNG
                              </h1>
                           </div>
                           <div>
                              <h4>{t("SCR_PLEASE_CONTACT_OUR_STAFF")}</h4>
                              <br />
                              <h4 className="italic">
                                 Liên hệ với nhân viên của chúng tôi
                              </h4>
                           </div>
                        </div>
                     )}
                  </div>
               </>
            )}
            {isLoading && (
               <div className="wrapper-loading">
                  <div className="lds-ellipsis">
                     <div></div>
                     <div></div>
                     <div></div>
                     <div></div>
                  </div>
               </div>
            )}
         </div>
         <Modal isOpen={isShowModal} style={customStyles}>
            <div className="alert-modal" style={{ paddingTop: "3rem" }}>
               <button
                  onClick={() => {
                     setIsShowModal(false)
                     isShowModalRef.current = false
                  }}
                  style={{
                     cursor: "pointer",
                     background: "none",
                     position: "absolute",
                     right: 8,
                     top: 8,
                     margin: 0,
                  }}
               >
                  <ClearIcon />
               </button>
               <p className="alert-modal__content">
                  <div className="time-box">
                     <label style={{ marginRight: 4 }}>Time: </label>
                     <div className="time-box-action">
                        <button
                           onClick={() =>
                              handelChangeTime("decrease", timeWaitList)
                           }
                        >
                           -
                        </button>
                        <input
                           type="number"
                           // onChange={(e) => setTimeWaitList(e.target.value)}
                           value={timeWaitList}
                           readOnly
                        />

                        <button
                           onClick={() =>
                              handelChangeTime("increase", timeWaitList)
                           }
                        >
                           +
                        </button>
                     </div>
                  </div>
               </p>
               <button
                  onClick={hanelChangetimeWaitList}
                  style={{ cursor: "pointer", padding: "8px 3em" }}
               >
                  Submit
               </button>
            </div>
         </Modal>
      </>
   );
}

export default WaitList;
