import axios from "axios";
import { API_URL_CUSTOMER_V3, GOOGLE_API_KEY } from "../config/index";
import { getCookie, getRefreshToken, getToken, set_cookie_shared_token } from "@iso/utils/helpers";
import { setAuthorization } from "@iso/utils/axios.configs";


export const apiCustomerV3 = axios.create({
  baseURL: API_URL_CUSTOMER_V3,
  timeout: 30000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});


apiCustomerV3.defaults.headers.common.Authorization = getToken()
  ? `Bearer ${String(getToken())?.replace(/"/g, "")}`
  : null;

apiCustomerV3.interceptors.response.use(
  (response) => response,
  async (error) => {
   
    const originalRequest = error.config;

    let message =
      error && error?.response?.data?.message
        ? error?.response?.data?.message
        : "Sorry, something went wrong";
    let status =
      error && error?.response?.status ? error?.response?.status : null;
    if (status === 401) {
      try {
        const response = await apiCustomerV3.post(
          `/customer/api/auth/refresh-token`,
          {
            refresh_token: String(getRefreshToken())?.replace(/"/g, ""),
          }
        );
        if (response?.data?.access_token) {
          const accessToken = response?.data?.access_token;
          const refreshToken = response?.data?.refresh_token;
          setAuthorizationApiCustomerV3(accessToken);
          setAuthorization(accessToken);
          set_cookie_shared_token("tokenShare", accessToken);
          set_cookie_shared_token("refreshTokenShare", refreshToken);
          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        } else {
          console.error("Failed to refresh access token");
        }
      } catch (refreshError) {
        // Handle the error that occurred during token refresh (e.g., redirect to login page)
        console.debug("Error during token refresh:", refreshError);
        // Perform the necessary actions here
      }
    } else if (status === 422) {
    } else if (status >= 500 || message === "Network Error") {
    } else if (status === 410) {
    } else {
    }

    return Promise.reject(error);
  }
);


export function setAuthorizationApiCustomerV3(token) {
  apiCustomerV3.defaults.headers.common.Authorization = token
    ? `Bearer ${token?.replace(/"/g, "")}`
    : null;
}

export  const GetProfileV3 = async () => {
  return await apiCustomerV3.get('/customer/api/profile');
};

export  const CheckUserExistApi = async (payload) => {
  return await apiCustomerV3.post('/customer/api/auth/check-user-exist', payload);
};